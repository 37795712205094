import React, { Component } from 'react'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import { Droppable } from 'react-beautiful-dnd';
//img

import caretLeft from '../../../../../images/general/caret-left.svg'
import caretRight from '../../../../../images/general/caret-right.svg'
import Warning from '../../../../../images/general/warning-sign.svg'
//icons
import problem_icon from "../../../../../images/general/problem.svg";
import {sortProcessesMonthlyView} from '../../../../../functions/data/sortProcesses'

import  * as momentBusinessDays  from 'moment-business-days';
import { isMobileView } from '../../../../../hooks/helper';
import "./Calendar.css"
import CalendarDraggable from './CalendarDraggable';

momentBusinessDays.updateLocale('us', {
    workingWeekdays: [0, 1, 2, 3, 4 ] 
});

let uniqid = require("uniqid");
class Calendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            infoPopup: false,
            processInfoData: {},
            infoPop: React.createRef()
        }
    }

    shouldComponentUpdate(_np, _ns){
        let _p = this.props; let _s = this.state;
        return (
                _np.fourWeeksDateChange !== _p.fourWeeksDateChange 
            ||  _np.user_or_order_type !== _p.user_or_order_type
            ||  _np.daysOffNumber !== _p.daysOffNumber
            ||  _np.disableDrag !== _p.disableDrag
            ||  _np.datesArray !== _p.datesArray
            ||  _np.daysOff !== _p.daysOff
            ||  _ns !== _s
        ) ? true : false;         
    }

    handleProcessMenu = (data,date) => {
        this.props.initProccessUpdatePopup(data,date)
    }

    goToDailyIfMobile = (day) => {
        const dynamicFactoryName = this.props.factoryName
        let dateNew = day.date
        dateNew = moment(dateNew).add(5, 'hours')._d
        this.props.history.push(`/${dynamicFactoryName}/daily/${dateNew} ${window.location.search}`)
    }

    setDayColor = (day) => {
        const { warnings } = day;
        return warnings ? 'red' : 'rgba(0, 0, 0, 0.6)'
    }

    backToToday = () => {
        this.props.backToToday()
    }

    findDayWarningForMobileUiHeader=()=>{
        const {datesArray} = this.props
        let datesArray_copy = JSON.parse(JSON.stringify(datesArray));
       
       let su_no_warning = datesArray_copy.filter(day=> day.dateName === "ראשון" && day.warnings).length <= 0
       let mo_no_warning = datesArray_copy.filter(day=> day.dateName === "שני" && day.warnings).length <= 0
       let tu_no_warning = datesArray_copy.filter(day=> day.dateName === "שלישי" && day.warnings).length <= 0
       let we_no_warning = datesArray_copy.filter(day=> day.dateName === "רביעי" && day.warnings).length <= 0
       let th_no_warning = datesArray_copy.filter(day=> day.dateName === "חמישי" && day.warnings).length <= 0
       let fr_no_warning = datesArray_copy.filter(day=> day.dateName === "שישי" && day.warnings).length <= 0
       let sa_no_warning = datesArray_copy.filter(day=> day.dateName === "שבת" && day.warnings).length <= 0
       let mobile_days_names = [su_no_warning, mo_no_warning,tu_no_warning, we_no_warning,th_no_warning,fr_no_warning,sa_no_warning]
       return mobile_days_names
    }

    previous_info_popup_id = '';
    initHandlerForInfoPopup = (event, popup_id) => {
        if(this.previous_info_popup_id){
            let tag = document.getElementById(this.previous_info_popup_id);
            tag && (tag.style.display = 'none');
        }
        if(event.target.offsetParent.nextSibling) {
            document.getElementById(event.target.offsetParent.nextSibling.id).style.display = 'block';
        } else if(event.target.parentElement.nextSibling) {
            document.getElementById(event.target.parentElement.nextSibling.id).style.display = 'block';
        } else {
            document.getElementById(event.target.parentElement.parentNode.nextSibling.id).style.display = 'block';
        }
        if(popup_id){
            let tag = document.getElementById(popup_id);
            tag && (tag.style.marginTop = this.setInfoTopView(event));
        }
        this.previous_info_popup_id = popup_id;
    };

    setInfoTopView = (event) => {
        let xPoint= event.clientX;
        let yPoint= event.clientY;
        let innerHeight = window.innerHeight;
        let height = innerHeight - yPoint;
        return (height < 160) ? '-78%' : '-12%';
    }

    closeHandlerInfoPopup = (event, popup_id) => {
        let is_hover_on_info = false;
        let popup = document.getElementById(popup_id)?.addEventListener('mouseenter', (e) => {
            is_hover_on_info = true;
        },false);
        setTimeout(() => {
            if(!is_hover_on_info){
                document.getElementById(popup_id).style.display = 'none';
            }
        },100)
    };

    checkIndexAndSetWidth = (index, data) => {
        let { process_width = 1} = data.proccess || {};
        // if (index % 7 === 4 && process_width > 1) {
        //     process_width = 1;
        // }
        return `calc(((100% + 0.9px) * ${process_width}) - 10px)`
    }

    checkIndexAt = (date, data) => {
        const { process_width = 1, fraction = true, zindex = false } = data.proccess || {};
        return process_width > 1 ? true : zindex
        // return (!fraction && process_width > 1) ? true : zindex
    }

    handleCurrentDayBackgroundColor(currentDate){
        const today = new Date();
        const originalDate = moment(today);
        const formattedDate = originalDate.format("DD/MM/YYYY");
        
        if (currentDate === formattedDate){
            return true
        } else {
            return  false
        }
    }


    createDummyProcesses = (datesArray) => {

        for (let i=0; i < datesArray.length; i++) {
            const {
                dayData,
            } = datesArray[i]
    
    
            for (let j=0; j < dayData.length; j++) {
                const _dayData = dayData[j];
                const {
                    order_id,
                    dummy,
                    proccess,
                } = _dayData
    
                if (typeof proccess === 'undefined') {
                    continue
                }
    
                const {
                    process_width
                } = proccess  
                if (process_width > 1 && !dummy) {
                    let k = 1 ;
                    while(k < process_width && k < datesArray.length - i) {
                        if (datesArray[i + k].dayData.length-1 <  j) {
                            k +=1;
                            continue;
                        } else {
                            this.insertDummyProcessWithoutMovingAlreadyPositionedDummys(datesArray[i + k].dayData, j, {
                                ..._dayData,
                                proccess: {
                                    ..._dayData.proccess,
                                    _id: `dummy_${_dayData.proccess._id}`
                                },
                                dummy: true,
                            })
                        }
                        k +=1;
                    }
                }
            }
        }

        return datesArray;
    }

    getProcessBrokeInHolidays = (datesArray, start, end) => {
        const processes = [];
        let counter = 0;
        let index = start;
        for (let i=start; i <= end && i < datesArray.length; i++) {
            if (datesArray[i].holiday != null && datesArray[i].holiday.status === 'Day Off') {
                if (counter > 0)  {
                    processes.push({
                        width: counter,
                        index: index - start,
                    });
                }
                counter = 0;
                index = i + 1;
            } else {
                counter +=1;
            }
        }

        if (counter > 0) {
            processes.push({
                width: counter,
                index: index - start,
            });
        }

        return processes;
    }


    insertDummyProcessWithoutMovingAlreadyPositionedDummys = (result, position , elem) => {
        let foundNonF = false;    
        let insertionIndex = position;
    
        while (insertionIndex < result.length && ('dummy' in result[insertionIndex] && result[insertionIndex]['dummy']&& foundNonF === false)) {
            if (!('dummy' in result[insertionIndex] && result[insertionIndex]['dummy'])) {
                foundNonF = true;
                break;
            }
            insertionIndex++;
        }

        if (!foundNonF && insertionIndex === result.length) {
            result.push(elem);
        } else {
            let i = insertionIndex;
            let lastElem = elem
    
            while(i < result.length) {
                if ('dummy' in result[i] && result[i]['dummy']) {
                    i++;
                    continue;
                } else {
                    const currentElem = result[i];
                    result[i] = lastElem;
                    lastElem = currentElem;
                    i++;
                }
            }
            result.push(lastElem)
        }
        return result;
    }

    
    transform = (datesArray) => {
        const orderProcessMap = {}
       
        // make all dates array to be sorted on the basis of process width 
        datesArray = datesArray.map((dateElem) => {
            const {
                dayData,
            } = dateElem;
            // dateElem.dayData = dayData.sort((a, b) =>  b.proccess.process_width - a.proccess.process_width)
            // dateElem.dayData = dayData.sort((a, b) =>  b.proccess.process_width - a.proccess.process_width)
            return dateElem;
        })

        //  remove all the skip skipForChekedinCheck process first ( this is for the duplication on these ones on scroll and load more)
        datesArray = datesArray.map((dateElem) => {
            const {
                dayData,
            } = dateElem;
            const filteredDayData = dayData.filter((dayDataElem) => {
                return !('skipForChekedinCheck' in dayDataElem.proccess) && !(dayDataElem.proccess?.isProcessCompletedNow) && !('dummy' in dayDataElem)
            })
            dateElem.dayData = filteredDayData
            return dateElem
        })

        for (let i=0; i < datesArray.length; i++) {
            const {
                dayData,
            } = datesArray[i]
            for (const _dayData of dayData) {
                const {
                    order_id,
                    proccess,
                } = _dayData

                if (typeof proccess === 'undefined') {
                    continue
                }

                const {
                    process_date,
                    process_endDate,
                    process_id
                } = proccess

                const id = `${order_id}_${process_id}`
                if (id in orderProcessMap) {
                    const {
                        start,
                        end,
                    } = orderProcessMap[id]
                    if (moment(process_date).isBefore(start)) {
                        orderProcessMap[id].start = process_date
                    } 

                    if (moment(process_endDate).isAfter(end)) {
                        orderProcessMap[id].end = process_endDate
                    } 
                } else {
                    orderProcessMap[id] = {
                        start: process_date,
                        end: process_endDate,
                    }
                }
            }
        }

        const checkedInDataMap = {}
        for (let i=0; i <  datesArray.length; i++) {
            const {
                dayData,
            } = datesArray[i]

            for (let j=0; j < dayData.length; j++) {
                const _dayData = dayData[j];
                const {
                    order_id,
                    proccess,
                } = _dayData

                if (typeof proccess === 'undefined') {
                    continue
                }

                const {
                    process_id
                } = proccess  
                const id = `${order_id}_${process_id}`
                if ((id in checkedInDataMap) && !proccess.skipForChekedinCheck) {
                    dayData.splice(j, 1)
                    j--;
                } else if ((id in checkedInDataMap) && proccess.skipForChekedinCheck) {
                    continue;
                } else {
                    checkedInDataMap[id] = true
                    const startEndData = orderProcessMap[id]
                    const {
                        start,
                        end
                    } = startEndData
                    const startDate = moment(start).startOf('day')
                    const endDate = moment(end).startOf('day');
                    const daysInBetween = endDate.diff(startDate, 'days')
                    _dayData.proccess.process_width = daysInBetween + 1
                    const startDateDay = moment(start).day();
                    let actualProcessWidth = _dayData.proccess.process_width
                    const processBrokenInHolidays = this.getProcessBrokeInHolidays(datesArray, i, i+ Math.min(daysInBetween, 5-startDateDay-1))
                    let brokenProcessIndex=0;
                    if (processBrokenInHolidays.length > 1) {
                        _dayData.proccess.process_width  = processBrokenInHolidays[0].width
                        for (let k=1; k < processBrokenInHolidays.length; k++) {
                            const { 
                                width,
                                index,
                            } = processBrokenInHolidays[k];
                            brokenProcessIndex +=1;
                            const newDayDataObjForExtension = {
                                ..._dayData,
                                proccess: {
                                    ..._dayData.proccess,
                                    process_width: width,
                                    skipForChekedinCheck: true,
                                    brokenProcessIndex: `${i}_${j}_${brokenProcessIndex}`,
                                }
                            }
                            if (i + index < datesArray.length) {
                                datesArray[i + index].dayData.splice(j, 0, newDayDataObjForExtension)
                            }
                        }
                    }
                    if (_dayData.proccess.process_width > 5-startDateDay) {
                        _dayData.proccess.process_width = 5-startDateDay
                        actualProcessWidth -= _dayData.proccess.process_width + 2
                        // while loop maybe to add in all the dates array at once
                        let m = i + 2 + proccess.process_width;
                        let brokenProcessIndex = 0;
                        while (true) {
                            const newDayDataObjForExtension = { ..._dayData, proccess: {..._dayData.proccess, skipForChekedinCheck: true, }}
                            if (m >= datesArray.length-1) {
                                break;
                            }
                            if (actualProcessWidth <= 0) {
                                break;
                            } else if (actualProcessWidth >  5) {
                                const processBrokenInHolidays = this.getProcessBrokeInHolidays(datesArray, m, m +4)
                                for (const process of processBrokenInHolidays) {
                                    const { 
                                        width,
                                        index,
                                    } = process
                                    brokenProcessIndex += 1;
                                    newDayDataObjForExtension.proccess.process_width = width
                                    newDayDataObjForExtension.proccess.brokenProcessIndex = brokenProcessIndex
                                    datesArray[m + index].dayData.push(newDayDataObjForExtension)
                                }
                                // newDayDataObjForExtension.proccess.process_width = 5;
                                // datesArray[m].dayData.push(newDayDataObjForExtension)
                                actualProcessWidth -= 7;
                                m +=7
                            } else {
                                const processBrokenInHolidays = this.getProcessBrokeInHolidays(datesArray, m, m + actualProcessWidth-1)
                                for (const process of processBrokenInHolidays) {
                                    const { 
                                        width,
                                        index,
                                    } = process
                                    brokenProcessIndex += 1;
                                    newDayDataObjForExtension.proccess.process_width = width
                                    newDayDataObjForExtension.proccess.brokenProcessIndex = `${i}_${j}_${brokenProcessIndex}`
                                    datesArray[m + index].dayData.push(newDayDataObjForExtension)
                                }
                                // newDayDataObjForExtension.proccess.process_width = actualProcessWidth;
                                // datesArray[m].dayData.push(newDayDataObjForExtension)
                                actualProcessWidth = 0;
                                break;
                            }
                        } 
                    }
                }
            }
        }

        // return datesArray;
        return this.createDummyProcesses(datesArray)
    }

    render() {
        const {fourWeeksDateChange, daysOffNumber, disableDrag, daysOff, user_or_order_type, getMoreDataOnScroll, handleScroll} = this.props
        let datesArray =  this.props.datesArray
        datesArray = this.transform(datesArray)
        let headerDatesString = datesArray && datesArray.length > 0 ? datesArray[0].parsedDate + ' - ' + datesArray[datesArray.length - 1].parsedDate:null
        let headerWeeksFirstString = datesArray && datesArray.length > 0 ? moment(datesArray[0].date).week(): null
        let headerWeeksEndString = datesArray && datesArray.length > 0  ? moment(datesArray[datesArray.length - 1].date).week() :null
        let mobile_days_names_warning_check = isMobileView() ? this.findDayWarningForMobileUiHeader() : []
        let mobileDaysNames = ['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת']
        let header_color = 'rgb(210 210 210)';
        let info_popup_position = {
            initial: -8,
            last: 100,
        }
        let blockDecrementation = false;
        return (
            <div className="month-view-page__calendar">
                
                <header className="month-view-page__calendar__header">
                    <figure id="mobile__dec__figure" style={blockDecrementation ? {pointerEvents:'none'}:{}} onClick={()=>{fourWeeksDateChange('dec')}}> <img className="monthly-view-arrows" src={caretRight} alt="right-arrow"/> </figure>
                    <figure id="mobile__dec__figure-left" onClick={()=>{fourWeeksDateChange('inc')}}> <img className="monthly-view-arrows" src={caretLeft} alt="left-arrow"/> </figure>
                    <button className="btn--back--today" onClick={()=>{this.backToToday()}} >
                        {/* <SvgIcon className="back--today--icon" component={EventIcon} /> */}
                        <p>חזור להיום</p>
                    </button>
                    {
                        this.props.apiStart && this.props.apiEnd 
                        ?   <React.Fragment>
                                <p style={{color: 'white'}}><span>{moment(this.props.apiStart).format('DD/MM/YYYY')}</span>-<span>{moment(this.props.apiEnd).format('DD/MM/YYYY')}</span></p>
                            </React.Fragment>
                        : ""
                    }
                    {/* <figure className="figure-arrows" style={blockDecrementation ? {pointerEvents:'none'}:{}} onClick={()=>{fourWeeksDateChange('dec')}}> <img className="monthly-view-arrows" src={caretRight} alt="right-arrow"/> </figure>
                    {!!(headerDatesString) && <h3>שבועות {headerWeeksFirstString} - {headerWeeksEndString} ( {headerDatesString} )</h3>}
                    <figure className="figure-arrows" onClick={()=>{fourWeeksDateChange('inc')}}> <img className="monthly-view-arrows" src={caretLeft} alt="left-arrow"/> </figure> */}
                </header>
                {
                    isMobileView() && <section className="month-view-page__calendar__mobile-days-panel">
                        {mobileDaysNames.map( (day,dayIndex) => {
                            if(!daysOff.includes(dayIndex))
                            return <div key={uniqid()} style={{width:`calc( 100% / ${ 7 - daysOffNumber})  `}}>
                                {mobile_days_names_warning_check[dayIndex] ? 
                                <p>{day}</p>
                                :
                                <p className="mobile__days__names__warning">{day} <img src={problem_icon} alt="problem"/> </p>
                                }
                                </div>
                        })}
                    </section>
                }

                    <section className="month-view-page__calendar__table" id="month-view-page__calendar__table_plan" onScroll={handleScroll}>
                    {/* <MonthViewNew/> */}

                    {/* <div className='month-view-page__calendar_infiniteScroll_container' style={{ height: '100vh', overflow: 'auto'}}> */}
                        {/* <InfiniteScroll
                            pageStart={1}
                            loadMore={this.props.loadMore}
                            hasMore={this.props.hasMoreData}
                            loader={this.props.loading && <div className="loader" key={0}><Loader /></div>}
                            useWindow={false}
                            // isReverse={true}
                            initialLoad={false}
                        > */}
                            {datesArray.map((day, day_index) => {
                                let hasWarnings = false
                                for (let i = 0; i < day.dayData.length; i++) {
                                    if (day.dayData[i]?.warnings?.length > 0 && !day.dayData[i].proccess.backlog) {
                                        hasWarnings = true
                                        break;
                                    }
                                }
                                

                                let isHalfDayBlank = false;
                                if (day.holiday) {
                                    if (day.dayData.length === 0) isHalfDayBlank = true;
                                }
                                let mobileParsedDate = day.parsedDate
                                mobileParsedDate = mobileParsedDate.substr(0, 5) + '/' + mobileParsedDate.substr(8)
                                let today_date = moment().format('DD/MM/YYYY'); // change are not required as of now for date formatting
                                today_date = today_date.substr(0, 5) + '/' + today_date.substr(8)

                                if (!day.dayOff)
                                    return <div 
                                    style={{ 
                                        width: `calc( 100% / ${7 - daysOffNumber})`,
                                        background: this.handleCurrentDayBackgroundColor(day.parsedDate) && 'rgb(225,225,225)'
                                        }} 
                                        className={hasWarnings ? "month-view-page__calendar__table__day month-view-page__calendar__table__day_css2 month-view-page__calendar__table__day--warning" : "month-view-page__calendar__table__day month-view-page__calendar__table__day_css2"} key={day.parsedDate} >
                                        <figure onClick={() => { this.goToDailyIfMobile(day) }}></figure>
                                        {day.holiday && day.holiday?.status === "Day Off" || day.dayOff ?
                                            <div
                                                style={{ marginBottom: '5px', color: this.setDayColor(day)}}
                                                onClick={() => { this.goToDailyIfMobile(day) }}
                                                className='sticky_header'
                                            >
                                                <header style={{ backgroundColor: '#cef8c1', }}>
                                                    <p>{day.dateName} {this.setDayColor(day) === 'red' ? <img className="warning_icon" src={Warning} alt="warning"></img> : ""}</p>
                                                    <p style={{display : 'flex'}}>
                                                        <p> <span title={day.holiday?.name}>{'(יום חופש) '}</span></p>
                                                        <span>{day.parsedDate}</span> 
                                                    </p>
                                                   
                                                    <span className={String(mobileParsedDate) === String(today_date) ? "today__mark" : ""}>{mobileParsedDate}</span>
                                                </header>
                                            </div>
                                            :
                                            day.holiday && day.holiday?.status === "Half Day" ?
                                                <div
                                                    style={{ marginBottom: '5px', color: this.setDayColor(day)}}
                                                    onClick={() => { this.goToDailyIfMobile(day) }}
                                                    className='sticky_header'
                                                >
                                                    <header style={{ backgroundColor: '#cef8c1' }}>
                                                        <p>{day.dateName} {this.setDayColor(day) === 'red' ? <img className="warning_icon" src={Warning} alt="warning"></img> : ""}</p>
                                                        <p style={{ display: 'flex' }}>
                                                            <p> <span title={day.holiday?.name}>{'(חצי יום)'}</span></p>
                                                            <span>{day.parsedDate}</span>
                                                        </p>
                                                        <span className={String(mobileParsedDate) === String(today_date) ? "today__mark" : ""}>{mobileParsedDate}</span>
                                                    </header>
                                                </div>
                                                :
                                                <div
                                                    style={{ marginBottom: '5px', color: this.setDayColor(day)}} 
                                                    onClick={() => { this.goToDailyIfMobile(day) }}
                                                    className='sticky_header'
                                                >
                                                    <header style={{ backgroundColor: header_color }}>
                                                        <p>{day.dateName} {this.setDayColor(day) === 'red' ? <img className="warning_icon" src={Warning} alt="warning"></img> : ""} </p>
                                                        <p> <span>{'(' + moment(day.date).week() + ') '}</span> {day.parsedDate}</p>
                                                        <span className={String(mobileParsedDate) === String(today_date) ? "today__mark" : ""}> {mobileParsedDate}</span>
                                                    </header>
                                                </div>
                                        }


                                        {
                                            day.holiday && day.holiday.status === 'Day Off' || day.dayOff ?
                                                <React.Fragment>
                                                    <div className="month-view-page__calendar__table__day--dayoff">
                                                        <p style={{ width: '100%' }}>{day.holiday.name}</p>
                                                    </div>
                                                </React.Fragment>
                                                :

                                                <Droppable 
                                                    droppableId={day.parsedDate} 
                                                    key={`${day_index}`}
                                                >
                                                    {(provided, snapshot) => (

                                                        <div
                                                            className="month-view-page__calendar__table__day__droppable"
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            style={{ height: (day.holiday && day.holiday.status !== 'Half Day') ? 'calc(100% - 10px)' : 'calc(100% - 40px)', ...provided.droppableProps.style, margin: '0px 0px', padding: '0px 0px', background: snapshot.isDraggingOver ? '#e5e5e5' : 'transparent' }}
                                                        >
                                                            {
                                                                !isHalfDayBlank ?
                                                                    <>
                                                                        {/* <List height={300} width={300} rowRenderer={this.getRowRender(day, disableDrag, user_or_order_type, day_index, info_popup_position)} rowCount={100} rowHeight={40} /> */}
                                                                        {sortProcessesMonthlyView(day.dayData).map((data, index) => {
                                                                            if (data !== undefined) {
                                                                                let resources_name = data?.proccess?.resource?.reduce((names, item) => {
                                                                                    names.push(item.name);
                                                                                    return names;
                                                                                }, []);
                                                                                let resourcesString = resources_name?.reduce((name, value) => {
                                                                                    return name += (name ? ` / ${value}` : `${value}`);
                                                                                }, '') || '';
                                                                                if (data?.dummy || data?.dummy2) {
                                                                                    return <div key={data.order_id} style={{
                                                                                        height: '38px',
                                                                                        marginBottom: '5px',
                                                                                        // background: data.dummy ? 'purple' : 'pink',
                                                                                    }}>
                                                                                        {/* {JSON.stringify(data.proccess.order_number)} */}
                                                                                    </div>
                                                                                }

                                                                                if (!data.proccess.backlog) {
                                                                                    return <CalendarDraggable
                                                                                        data={data}
                                                                                        index={index}
                                                                                        disableDrag={disableDrag}
                                                                                        day_index={day_index}
                                                                                        day={day}
                                                                                        user_or_order_type={user_or_order_type}
                                                                                        handleProcessMenu={this.handleProcessMenu}
                                                                                        checkIndexAt={this.checkIndexAt}
                                                                                        checkIndexAndSetWidth={this.checkIndexAndSetWidth}
                                                                                        resourcesString={resourcesString}
                                                                                        info_popup_position={info_popup_position}
                                                                                        closeHandlerInfoPopup={this.closeHandlerInfoPopup}
                                                                                        initHandlerForInfoPopup={this.initHandlerForInfoPopup}
                                                                                    />
                                                                                }

                                                                            }
                                                                        })}
                                                                    </>
                                                                    :
                                                                    <div className="month-resource-page__calendar__table__day--dayoff" style={{ height: 'inherit' }}>
                                                                        <p style={{ width: '100%' }}>
                                                                            {day.holiday?.name}
                                                                        </p>
                                                                    </div>
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>

                                        }
                                    </div>

                            })}
                        {/* </InfiniteScroll> */}
                    {/* </div> */}
                      
                    </section> 
               

               
            </div>
        )
    }
}

export default withRouter(Calendar)
