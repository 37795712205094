import React, { useCallback } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from 'lodash'
import { useState } from "react";
import { useEffect } from "react";
import { MULTIPLE_VALUES } from "../../../../../../constants/customers-page";
import { CHECK_LIST_TRANSLATION } from "../../../../../../constants/translations/customersPage";
import StyledCheckbox from "../../../../../../components/reused-components/StyledCheckbox";
import { CheckListService } from "../../../../../updatePopups/reviewPopup/parts/systemsCheckListSection/services";
import { useStyles } from "./styles";
import { Box, withStyles } from "@material-ui/core";
import ImageButton from "../../../../Buttons/ImageButton/ImageButton";
import { API } from '../../../../../../tools/keys/keys';
import "./CheckListRowNew.scss"

const StyledTableCell = withStyles({
    root: {
        borderBottom: "1px solid #CACBCC",
        borderTop: "1px solid #CACBCC",
        // border: '1px solid red',
        maxWidth: '100px',
        padding: '0px 2px',
        fontFamily: 'Rubik !important',
        "&:last-child": {
            borderBottom: "1px solid #CACBCC",
            borderTop: "1px solid #CACBCC",
            borderLeft: "1px solid #CACBCC",
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',

        },
        "&:first-child": {
            borderRight: "1px solid #CACBCC",
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottom: "1px solid #CACBCC",
            borderTop: "1px solid #CACBCC",

        }
    },
})(TableCell);

const CheckListRowNew = (props) => {
    const classes = useStyles()
    const { row, rowIdx, setValueHandler, newSetValueHandler, uploadPhotoHandler, removeFileHandler,
        setRowTitle, getTestsPerFloor, orderId, rowTitle
        // orderId, actualSystemId
    } = props


    let { message } = row;
    const [imageStatus, setImageStatus] = useState(false)

    useEffect(() => {
        if (message) {
            setImageStatus(prev => !prev)
            setTimeout(() => {
                setImageStatus(prev => !prev)
                delete row.message;
            }, 3000);
        }
    }, [message, row.message])


    const { PROPER, IMAGE_MUST_BE_ADDED } = CHECK_LIST_TRANSLATION

    const getPhotoMandatoryMessage = useCallback((row) => {
        const PROPER_NOT_PROPER_COLUMN_INDEX = 0
        const PHOTO_COLUMN_INDEX = 3
        const isProperValue = row?.values[PROPER_NOT_PROPER_COLUMN_INDEX].value
        const photoValue = row?.values[PHOTO_COLUMN_INDEX].value
        if (isProperValue && isProperValue !== PROPER && !photoValue.length) {
            return IMAGE_MUST_BE_ADDED
        }
    }, [IMAGE_MUST_BE_ADDED, PROPER])

    const createOptions = useCallback((options) => {
        const result = options?.map(i => i.option) || []
        return _.compact(result)
    }, [])

    const openAdditionalCheckListHandler = useCallback((test) => {
        const { test_union, row_title } = test
        if (test_union) {
            setRowTitle(row_title)
        }
    }, [setRowTitle])

    const openTestPerFloorHandler = useCallback((test) => {
        getTestsPerFloor(test, orderId, rowIdx)
    }, [getTestsPerFloor, orderId, rowIdx])

    const formatValue = (input) => {
        // format value for multiple value (if string push into empty array), where input number  >= 0
        const { typeOfInput, value } = input
        if (typeOfInput >= MULTIPLE_VALUES) {
            return value ? (typeof value === 'string' ? [value] : value) : []
        }
        return value || ''
    }

    return (<TableRow >
        <StyledTableCell
        
            onClick={() => {
                row.test_union && openAdditionalCheckListHandler(row);
                row.test_per_floor_id && openTestPerFloorHandler(row)
            }}
            style={CheckListService.setRowTitleActive(row)}
        >
            <span className={classes.testName} style={{ padding: row.test_union ? "16px 0" : '0' }}>
                {/* <img src={row.icon} className={classes.testIcon} /> */}
                <p style={CheckListService.setRowTitleColor(row)} className={classes.title}>{row.row_title} {rowTitle}</p>
            </span>
        </StyledTableCell>
        {row.values &&
            row.values.map((i, colIdx) => {
                return (colIdx !== row.values.length - 1 ? /*temporary excluded last field from showing*/
                    <StyledTableCell className={`testTableCell ${i._id} ${(formatValue(i) && (formatValue(i) !== PROPER)) ? 'redColor' : ''}`}
                        // className={classes.tableCellStyle}
                        key={i._id}
                        align="right"

                    >
                        {i.typeOfInput === "number" ? (
                            <TextField
                                placeholder={i.placeholder}
                                type={'number'}
                                value={i.value}
                                className={`${classes.maxWidth} ${classes.inputBoundary}`}
                                onChange={(e) =>
                                    newSetValueHandler(rowIdx, colIdx, e.target.value)
                                }
                                onBlur={(e) => setValueHandler(rowIdx, colIdx, e.target.value)}
                            />
                        ) : i.typeOfInput === "photo" ? (
                            <Grid className={classes.photoCell}>
                                <p className={classes.mandatoryPhotoMessage}>{getPhotoMandatoryMessage(row)}</p>
                                {imageStatus && <p className={classes.mandatoryPhotoMessage}>{message.name}</p>}
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                        <input
                                            id={`file-upload-${rowIdx}-${colIdx}`}
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) => uploadPhotoHandler(rowIdx, colIdx, e.target)}
                                        />

                                        {i?.value?.length ? (
                                            i.value.map((m, index) => (
                                            <Box key={index} position="relative" display="inline-block" margin="2px">
                                                {/* Close Button */}
                                                <span
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    removeFileHandler(rowIdx, colIdx, m);
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    top: "-5px",
                                                    right: "-5px",
                                                    backgroundColor: "rgba(0,0,0,0.6)",
                                                    color: "white",
                                                    borderRadius: "50%",
                                                    width: "16px",
                                                    height: "16px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontSize: "12px",
                                                    cursor: "pointer",
                                                    fontWeight: "bold",
                                                    lineHeight: "16px",
                                                }}
                                                >
                                                <CloseIcon fontSize="small" />
                                                </span>

                                                {/* Image Preview */}
                                                <img
                                                src={m.includes("data") ? m : `${API}${m}`}
                                                alt={`uploaded-${index}`}
                                                width="40px"
                                                height="40px"
                                                style={{ borderRadius: "4px" }}
                                                />
                                            </Box>
                                            ))
                                        ) : null}

                                        {/* Upload Button */}
                                        <label htmlFor={`file-upload-${rowIdx}-${colIdx}`}>
                                            <ImageButton 
                                            data="Upload photo" 
                                            isLabel={false} 
                                            clickHandler={() => document.getElementById(`file-upload-${rowIdx}-${colIdx}`).click()} 
                                            />
                                        </label>
                                        </Box>
                                </Grid>
                            </Grid>
                        ) : parseInt(i.typeOfInput) ? (
                            <Autocomplete
                                className={colIdx === 1 ? `okIncorrect ${classes.maxWidth} ${classes.inputBoundary}` : `okIncorrect ${classes.inputBoundary}`}
                                classes={{ popper: classes.styledOptionsList }}
                                size='small'
                                freeSolo
                                multiple={parseInt(i.typeOfInput) >= MULTIPLE_VALUES}
                                options={createOptions(i?.options)}
                                getOptionLabel={(option) => option && typeof option === 'string' ? option : (option?.join?.() || '')}
                                // value={(i?.value?.length) ? i?.value : []}
                                value={formatValue(i)}
                                onChange={(e, value) =>
                                    newSetValueHandler(rowIdx, colIdx, value)
                                }
                                onBlur={(e) => setValueHandler(rowIdx, colIdx, e.target.value)}
                                renderInput={(params) => {
                                    return <TextField
                                        padding='dense'
                                        {...params}
                                        variant="standard"
                                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                                    />
                                }}
                            />
                        ) : i.typeOfInput === "text" ? (
                            <TextField
                                multiline
                                rows={4}
                                variant="outlined"
                                placeholder={i.placeholder}
                                value={i.value}
                                onChange={(e) => newSetValueHandler(rowIdx, colIdx, e.target.value)}
                                onBlur={(e) => setValueHandler(rowIdx, colIdx, e.target.value)}
                                InputProps={{ disableUnderline: true }}
                                className="commentsClass"
                            />
                        ) : i.typeOfInput === "checkbox" ? (
                            <StyledCheckbox
                                checked={i.value}
                                changeHandler={(e) =>
                                    setValueHandler(rowIdx, colIdx, e.target.checked)
                                }
                            />
                        )
                            : i.typeOfInput === "read" ? <p className={classes.maxWidth}>{i.value}</p> : null
                        }
                    </StyledTableCell> : null
                )
            })}

    </TableRow>)
}

export default CheckListRowNew;