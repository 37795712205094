import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd';
// import { List } from 'react-virtualized';
//redux configs 
import { connect } from "react-redux"
import * as actions from "../../../actions/actions"
//sass
import '../../../sass/montlyDashboard/_month-view-page.scss'
//components
import Calendar from './parts/Calendar/Calendar'
import Completions from './parts/Completions/Completions'
import UpdateProccessPopup from '../../updatePopups/UpdateProccessPopup'
import OnDragPopup from '../../updatePopups/OnDragPopup'
import SpreadingPopup from '../../updatePopups/SpreadingPopup'
//functions
import { appGetOrders, montlyUpdateOrders, montlyUpdateOrdersForConst, updateProcess, getWarnings, setEndDateForProcess, getCompletion } from '../../../functions/api/orders'
import { removeBacklogDuplications } from './MonthViewFunctions'
import { isSameDay } from '../../../functions/general/general'
//api calls
import { generalGetRequest } from '../../../functions/api/general'
//external variables
import ConstantPopup from '../../updatePopups/ConstantPopup';
import jwt_decode from "jwt-decode";
import { PER_USER, VIEW_ONLY } from '../../../tools/keys/variables'
import * as momentBusinessDays from 'moment-business-days';
import Loader from '../../LoaderNew/Loader'
import { isWeekChange, closePopupOnBackButton, dateMatch, monthlyDataView, onScroll } from '../../../hooks/helper';
import { SERVICE } from '../../../constants';
import {store} from '../../../index';
import { setDraggingDestination } from '../../../actions/generalActions';

momentBusinessDays.updateLocale('us', {
    workingWeekdays: [0, 1, 2, 3, 4]
});


class MonthView extends Component {

    constructor() {
        super()

        this.state = {
            counter: 0,
            page: 0,
            stopApi: false,
            orders: [],
            currentDate: moment().subtract(1, 'weeks').startOf("week"),
            datesArray: [],
            prevState: [],
            popup: false,
            updateApiBody: null,
            errPopupState: null,
            updateProcessPopup: false,
            spreadingPopup: false,
            selectedProcess: {},
            warnings: [],
            loader: false,
            reasonPopup: false,
            spreadingBodyData: {},
            constant_spred: false,
            simpleDateArray1: [],
            simpleDateArray: [],
            end_dateChanged: false,
            forCacheData: [],
            apiFetchingTrack: {

            },
            completionData:[],
            completionCount:0,
            completionPage: 0,
            tempIdRelatedToComp:null,
            prevCompletionData:[],
            spreading: false,
            apiDataLastStart: null,
            apiDataLastEnd: null,
            hasMoreData: true,
            loading: false,
        }
        window.onpopstate = (event) => closePopupOnBackButton(this.state.updateProcessPopup, (data) => {data && this.closeUpdateMenu()});
    }
    

    componentWillMount() {
        let pathname = window.location.pathname
        this.props.setRouteLocation(pathname) // to update the location path in the header tabs
        // dynamic factory name in url 
        const dynamicFactoryName = this.props.login.user.factory_name
        let initDate = this.props.match.params.date

        if (!initDate) {
            let searchQuery = window.location.search
            this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}${searchQuery ? searchQuery : ''}`)
            // this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}`)
            this.initializeData()
        } else {
            this.setState({
                currentDate: moment(initDate).startOf("week"),
            }, () => {
                this.initializeData()
            })
        }
    }

    
    shouldComponentUpdate(nextProps, nextState){
        if(
        (nextState.updateProcessPopup !== this.state.updateProcessPopup)
         || (this.state.loader !== nextState.loader)
         || (this.state.datesArray !== nextState.datesArray)
         || (nextProps != this.props)
         || (nextState.popup !== this.state.popup)
         || (nextState.spreadingPopup !== this.state.spreadingPopup)
         || (this.state.completionData !== nextState.completionData)
         )return true;
        return false;
    }

    componentWillReceiveProps(nextProps) {
        const obj_true = nextProps.login.lastAddedOrderId  && this.props.login.lastAddedOrderId !== nextProps.login.lastAddedOrderId
        if (obj_true) {
            this.afterUpdateOrderProcess(nextProps.login.lastAddedOrderId)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (this.props.process.show_bids !== prevProps.process.show_bids)
            ||
            (prevProps.location.search !== this.props.location.search)
            ||
            (JSON.stringify(prevProps.login.selectedDepartment) !== JSON.stringify(this.props.login.selectedDepartment))
            ||
            (JSON.stringify(prevProps.login.selectedManager) !== JSON.stringify(this.props.login.selectedManager))
            ||
            (prevProps.login.selectedUser) !== (this.props.login.selectedUser)
            ||
            (prevProps.process.show_first_uncomplete_process !== this.props.process.show_first_uncomplete_process)
        ) {
            this.initializeData()
        }

        if (prevProps.mainPopup.eddited_order_id !== this.props.mainPopup.eddited_order_id && this.props.mainPopup.eddited_order_id){
            this.repositionConstantAfterEditInPopup(this.props.mainPopup)

        }
    }



    repositionConstantAfterEditInPopup = async (information) => { 
        if (isSameDay(new Date(information.order_data.due_date), new Date(information.new_due_date_after_edit_from_reservation_popup)))
            return

        //open the popup here
        this.props.updateConstantPopup(true)

        //api call to retrieve last process
        let res = await generalGetRequest(`/system/order-process/get-last-order-process?order_id=${information.order_id}`)
        let selectedProcess = {
            _id: res.result._id,
            order_number: information.order_data.order_number,
            order_id: information.order_id,
            due_date: new Date(information.order_data.due_date),
            date: new Date(information.new_due_date_after_edit_from_reservation_popup),
            client_name: information.order_data.client_name,
            warnings: [],
            process: res.result,
        }

        this.setState({
            updateApiBody: selectedProcess,
            spreadingBodyData: selectedProcess
        })

    }

    updateConstantToSpred = (boolean) => {
        this.setState({
            constant_spred: boolean
        })
    }

    deleteBacklogsDuplications = (process) => {

        let newState = removeBacklogDuplications(process, this.state.datesArray)
        this.setState({ datesArray: newState })
    }

    //  paginated data on scroll
    loadMoreMonthlyViewData = async (next) => {
        let from;
        let to;
        this.setState({ loader: true })
        if (next) {
            from = moment(this.state.apiDataLastEnd).add(1, 'days')
            to = moment(this.state.apiDataLastEnd).add(28, 'days');
        } else {
            to = moment(this.state.apiDataLastStart).subtract(1, 'days');
            from = moment(to).subtract(27, 'days');
        }
        
        let urlOrderNumber = window.location.search.replace('?order_number=', '')
        let selectedDepartment = this.props.login.selectedDepartment._id
        let employee_id = this.props.login.selectedManager._id || '';
        let started = this.props.process.show_bids
        // let selectedUser = this.props.login.selectedUser
        const user_id = jwt_decode(this.props.login.user.token)._id
        const selectedUser = this.props.login.user.privileges.includes(PER_USER) ? user_id : this.props.login.selectedUser;
        let current_process = this.props.process.show_first_uncomplete_process;
        const promises = []
        let i=1;
        const ordersData = []
        if (next) {
            while (i <= 4) {
                promises.push(appGetOrders(moment(from).add(7 * (i - 1), 'days')._d, moment(from).add((7 * i) - 1, 'days')._d, 'L', urlOrderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process))
                i +=1 
            }
            const responses = await Promise.all(promises)
            for (const response of responses) {
                ordersData.push(...response)
            }
        } else {
            while ( i <= 4) {
                promises.push(appGetOrders(moment(to).subtract(7 * (i)-1, 'days')._d, moment(to).subtract((7 * (i-1)), 'days')._d, 'L', urlOrderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process))
                i +=1 
            }
            const responses = await Promise.all(promises)
            for (const response of responses) {
                ordersData.unshift(...response)
            }
        }


        let _datesArray = this.buildDatesArray(moment(from).add(0, 'days'), ordersData);
        let resetAndMore = true
        let _datesArr = monthlyDataView(_datesArray, {reset:resetAndMore, addMore: resetAndMore});
        const apiDataLastStartEndObj = {}
        if (next) {
            apiDataLastStartEndObj.apiDataLastEnd = to;
        } else {
            apiDataLastStartEndObj.apiDataLastStart = from;
        }
        this.setState({
            datesArray:  next ? this.state.datesArray.concat(_datesArr) : _datesArr.concat(this.state.datesArray),
            forCacheData: _datesArr,
            loader: false,
            apiFetchingTrack:{ order_number: urlOrderNumber},
            ...apiDataLastStartEndObj,
        })
        setTimeout(() => this.props.setCsvMonthly(_datesArray),1)
    }

    // to load the data first time
    initializeData = async () => {
        if (!this.state.spreadingPopup)
        this.setState({ loader: true });
        // this.buildWorkingDatesArray(this.state.currentDate)
        //set loader if spread popup is closed only
        let from = this.state.currentDate._d
        let to =  moment(this.state.currentDate).add(27, 'days')._d;
        let urlOrderNumber = window.location.search.replace('?order_number=', '')
        let selectedDepartment = this.props.login.selectedDepartment._id
        let employee_id = this.props.login.selectedManager._id || '';
        let started = this.props.process.show_bids
        // let selectedUser = this.props.login.selectedUser
        const user_id = jwt_decode(this.props.login.user.token)._id
        const selectedUser = this.props.login.user.privileges.includes(PER_USER) ? user_id : this.props.login.selectedUser;
        let current_process = this.props.process.show_first_uncomplete_process;
        const promises = []
        let i=1;
        while ( i <= 4) {
            promises.push(appGetOrders(moment(from).add(7 * (i-1), 'days')._d, moment(from).add((7 * i) -1, 'days')._d, 'L', urlOrderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process))
            i +=1 
        }
        const ordersData = []
        const responses = await Promise.all(promises)
        for (const response of responses) {
            ordersData.push(...response)
        }
        // ordersData.then(apiOrders => {
        let _datesArray = this.buildDatesArray(this.state.currentDate, ordersData);
        let resetAndMore = !!urlOrderNumber || !!this.state.apiFetchingTrack?.order_number || !!this.state.apiFetchingTrack?.next_date ||  this.state.datesArray.length === 0;
        let _datesArr = monthlyDataView(_datesArray, {reset:resetAndMore, addMore: resetAndMore});
        this.setState({ datesArray: [] }, () => {
            this.setState({datesArray: _datesArray,
                forCacheData: _datesArray,
                loader: false,
                apiFetchingTrack:{ order_number: urlOrderNumber},
                apiDataLastStart: from,
                apiDataLastEnd: to,
            })
        })

        setTimeout(() => this.props.setCsvMonthly(_datesArray),1)
        // })
        // .catch((err) => {this.setState({ loader: false }); console.error('errr: ',err)})

        let page = 0;
        let limit = this.state.completionPage > 0 ? 50 + this.state.completionPage : 50;
        
        getCompletion(limit, page, 'L', selectedDepartment, urlOrderNumber, employee_id, selectedUser, current_process).then(res => {
            let {data=[], count} = res.result;
            this.setState({
                completionData:data,
                completionCount:count
            });
        }).catch(error => {console.error('completion api failed!')});
        // set api data to redux for order adding api call to add order that was added to UI
        let addOrderApiPayload = {
            from,
            to,
            view: 'L',
            department_id: this.props.login.selectedDepartment ? this.props.login.selectedDepartment._id : null
        }
        this.props.setAddOrderPayload(addOrderApiPayload)
    }
    

    fourWeeksDateChange = (operator) => {
        this.setState({ stopApi: false, orders: [], from: false, to: false, counter: 0, apiFetchingTrack:{ next_date: true} }, () => {

            if (operator === 'inc') {
                this.setState(prevState => ({
                    currentDate: (prevState.currentDate).add(28, 'day'),
                    loader: true
                }), async () => {
                    // dynamic factory name in url 
                    const dynamicFactoryName = this.props.login.user.factory_name
                    let searchQuery = window.location.search
                    this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}${searchQuery ? searchQuery : ''}`)
                    this.initializeData()
                })
            } else {
                this.setState(prevState => ({
                    currentDate: (prevState.currentDate).subtract(28, 'day'),
                    loader: true
                }), async () => {
                    // dynamic factory name in url 
                    const dynamicFactoryName = this.props.login.user.factory_name
                    let searchQuery = window.location.search
                    this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}${searchQuery ? searchQuery : ''}`)
                    this.initializeData()
                })
            }
        })
    }

    buildWorkingDatesArray = (date) => {
        let holidayDatesArray = this.props.login.user.holidays.map(item => moment(item.date).format('DD/MM/YYYY'))
        let daysOffArray = this.props.login.user.off_days.map(item => item)
        let simpleDateArrayLocal = []
        let weekDays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
        for (let i = 0; i < 28; i++) {

            let incrementedDate = moment(date).add(i, 'days')
            let dayData = []
            let holidayIndex = -1
            let holiday = false
            let dayOff = false
            
            for (let j = 0; j < holidayDatesArray.length; j++) {
                if (holidayDatesArray[j] === incrementedDate.format('DD/MM/YYYY')) {
                    holidayIndex = j
                    holiday = true
                    break
                }
            }

            for (let j = 0; j < daysOffArray.length; j++) {
                if (daysOffArray[j] === moment(incrementedDate).day()) {
                    dayOff = true
                    break
                }
            }

            if(!holiday && !dayOff)
                simpleDateArrayLocal.push(new Date(moment(incrementedDate).add(1, 'days')._d).toISOString().split('T')[0])

            
        }

        this.setState({
            simpleDateArray1: [],
            simpleDateArray1: simpleDateArrayLocal
        })
    }

    resetSimpleDateArray = () => {
        this.setState({ 
            simpleDateArray: [],
            simpleDateArray1: [] 
        });
    }

    prepareSimpleDateArray = () => {
        this.resetSimpleDateArray()
        if(this.state.datesArray.length > 0) {
            let _simple_date_Array = []
            var that = this
            that.state.datesArray.map(day=>{ 
                if(!day.dayOff && !_simple_date_Array.includes(day.parsedDate)) {
                    _simple_date_Array.push(day.parsedDate)
                }
            });
            this.setState({ 
                simpleDateArray: _simple_date_Array,
            });
        }
    }

    buildDatesArray = (date, _orders) => {
        const factoryType = this.props.login.user.type_of_factory;
        const isService = factoryType === SERVICE
        let holidayDatesArray = this.props.login.user.holidays.map(item => moment(item.date).format('DD/MM/YYYY'))
        let daysOffArray = this.props.login.user.off_days.map(item => item)
        let newDateDataArray = []
        let weekDays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
        for (let i = 0; i < 28; i++) {
            let incrementedDate = moment(date).add(i, 'days')
            let dayData = []
            let holidayIndex = -1
            let dayOff = false

            _orders.map(order => {
                order.processes.map((proccess, index) => {
                    if (
                        (this.checkIfSameDay(proccess.process_date, incrementedDate._d) && !proccess.backlog)
                        ||
                        (proccess.backlog && this.checkIfSameDay(incrementedDate._d, moment(date)._d))
                    ) {
                        dayData.push({
                            order_number: proccess.order_number,
                            order_id: proccess.order_id,
                            due_date: proccess.due_date,
                            client_name: proccess.client_name,
                            warnings: proccess.warnings,
                            proccess: { ...proccess },
                            // address: proccess.address,
                            // city: proccess.city
                        })
                    }
                })
            })

       dayData = dayData.map((day) => {
                   return day;
                 })

            for (let j = 0; j < holidayDatesArray.length; j++) {
                if (holidayDatesArray[j] === incrementedDate.format('DD/MM/YYYY')) {
                    holidayIndex = j
                }
            }


            for (let j = 0; j < daysOffArray.length; j++) {
                if (daysOffArray[j] === moment(incrementedDate).day()) {
                    dayOff = true
                }
            }

            newDateDataArray.push({
                date: JSON.parse(JSON.stringify(incrementedDate._d)),
                parsedDate: incrementedDate.format('DD/MM/YYYY'),
                dateName: weekDays[incrementedDate.day()] ? weekDays[incrementedDate.day()] : incrementedDate.format('dddd'),
                holiday: holidayIndex !== -1 ? this.props.login.user.holidays[holidayIndex] : null,
                dayOff,
                warnings: this.getWarningFromDayData(dayData, false, 0),
                dayData
            })

        }
        return newDateDataArray
    }

    getWarningFromDayData = (inputArray,status, index) => {
        if(status || inputArray.length === index)return status;
        let { warnings } = inputArray[index]
        return this.getWarningFromDayData(inputArray, warnings, ++index);
    }

    checkIfSameDay = (d1, d2) => {
        let dateOne = new Date(d1)
        let dateTwo = new Date(d2)
        return dateOne.getFullYear() === dateTwo.getFullYear() &&
            dateOne.getMonth() === dateTwo.getMonth() &&
            dateOne.getDate() === dateTwo.getDate();
    }

    findDateIndexInDatesArray = (datesArray, date) => {
        for (let i=0; i < datesArray.length; i++) {
            const {
                parsedDate,
            } = datesArray[i];
            if (moment(date).isSame(moment(parsedDate, "DD/MM/YYYY"), 'day')){
                return i;
            } 
        }
        return null;
    }

    // this function is called to update the process date etc on dnd
    handlePopupChoice = async (param, constantData = false,) => {
        let { constant_spred , end_dateChanged,spreading } = this.state;
        if (param === 'yes') {
            let newBody = {
                _id: (this.state.updateApiBody.process.original || this.state.updateApiBody._id),
                date: this.state.updateApiBody.endDate,
                view: this.state.updateApiBody.view,
                from: this.state.updateApiBody.from,
                to: this.state.updateApiBody.to,
                order_employee_id: null,
                department_id: this.props.login.selectedDepartment._id,
                order_id: this.state.updateApiBody.order_id
            }
            this.setState({ spreadingPopup: false, popup: false, loader: true });
            if (this.state.updateApiBody.toBacklog) { // If process goes to  backlog;
                let res = await updateProcess(this.state.updateApiBody.process)
                !res.ok && this.initializeData();  // dont call this
                this.setState({ loader: false, popup: false, prevState: [], prevCompletionData: [] });
                return;
            }

            let res = {ok:false};
            let is_same_date = dateMatch(new Date(this.state.updateApiBody.process.process_date)) === dateMatch(new Date(this.state.updateApiBody.date));
            if (this.state.updateApiBody.process.constant && !constant_spred) {
                let body = { ...this.state.updateApiBody }
                if (constantData) {
                    body.reason = constantData.reason.value;
                    body.approved_by = constantData.approveName.value;
                }
                await   (this.state.updateApiBody.process);
                !is_same_date && (res = await montlyUpdateOrdersForConst(body, this.props.login.selectedDepartment._id));
            } else {
                let body = { ...this.state.updateApiBody }
                if (constantData) {
                    body.reason = constantData.reason.value;
                    body.approved_by = constantData.approveName.value;
                }
                if(
                        !this.state.updateApiBody.process.constant || spreading
                    ){
                    const updateResponse = await updateProcess(this.state.updateApiBody.process); 
                    !is_same_date && (res = await montlyUpdateOrders(body, spreading, this.props.login.selectedDepartment._id));
                   
                    const { ok} = res;
       
                    if (end_dateChanged) {
                  
                        let {_id: id} = res.result || {};
                        let val = id ? {...newBody, _id: id} : {...newBody};
                        let spread = this.state.updateApiBody.process.constant ? false : spreading               
                        await setEndDateForProcess(val, spread).then(res2 => {
             
                            res = res2;
                        }).catch(err => console.error(err));
                        this.setState({end_dateChanged: false})
                    }

             
                    if (!res?.ok) {
                        this.setState({ errPopupState: res.result , loader: false, popup: res.result });
                    }
                    // process after update details
                    const getUpdatedProcessResponse = await getCompletion(1, 0, 'L', this.props.login.selectedDepartment._id, this.state.updateApiBody.process.order_number, this.props.login.selectedManager._id, this.props.login.user.privileges.includes(PER_USER) ? jwt_decode(this.props.login.user.token)._id : this.props.login.selectedUser, false, this.state.updateApiBody.process._id);
                    const {
                        ok: ok2,
                        result,
                    } = getUpdatedProcessResponse

               
                    const getUpdatedOrderResponse = await appGetOrders('Sat Jan 13 2020 00:00:00 GMT 0530(India Standard Time)', 'Sat Jul 13 2027 00:00:00 GMT 0530 (India Standard Time)', 'L', this.state.updateApiBody.process.order_number, this.props.login.selectedDepartment._id, this.props.login.selectedManager._id, "")
                   
                    if (ok2) {
                        let isAtCorrectLocation = false;
                        let sourcePositionIndex;
                        let sourceColumnIndex;
                        let targetPositionIndex;
                        let columnChanges = []
                        const updatedDatesArray = this.state.datesArray.map((dateObj, rowIndex) => {
                            const parseDate = dateObj.parsedDate;
                            if (moment(result.data[0]['proccess']['process_date']).startOf('day').isSame(moment(parseDate.toString(), "DD/MM/YYYY"))) {
                                targetPositionIndex = rowIndex;
                            }
                            return {
                                ...dateObj,
                                dayData: dateObj.dayData.filter((dayData) => !('brokenProcessIndex' in dayData.proccess)).map((dayData, colIndex) => {
                                    if(getUpdatedOrderResponse.length){
                                        let processIdOfDayData = dayData.proccess.process_id;
                                        let orderIdOfDayData = dayData.proccess.order_id;
                                        for (const _updatedProcess of getUpdatedOrderResponse) {
                                            for (const process of _updatedProcess.processes) {
                                                const {
                                                    order_id,
                                                    process_id,
                                                } = process
                                                if ( order_id === orderIdOfDayData && process_id === processIdOfDayData) {
                                                    const updatedStartDate = moment(process.process_date).startOf('day')
                                                    const updatedEndDate = moment(process.process_endDate).startOf('day')
                                                    dayData.proccess = {
                                                        ...dayData.proccess,
                                                        process_date: updatedStartDate,
                                                        process_endDate: updatedEndDate,
                                                        process_width: updatedEndDate.diff(updatedStartDate, 'days') + 1,
                                                    }
                                                    break
                                                }
                                            }
                                        }
                                    }
                                    return dayData;
                                })
                            }
                        })


                        for (let i=0; i < updatedDatesArray.length; i++) {
                            const data = updatedDatesArray[i]
                            const {
                                dayData,
                                parsedDate,
                            } = data

                            // order id, process id different
                            // date of the parsed date is not equal to process data
                            for (let j=0; j < dayData.length; j++)  {
                                const _dayData = dayData[j]
                                const {
                                    process_date,  
                                    // process_endDate,
                                } = _dayData.proccess

                                if (!(moment(process_date, 'DD/MM/YY').isSame(moment(parsedDate, 'DD/MM/YY')))) {
                                    const destIndex = this.findDateIndexInDatesArray(updatedDatesArray, process_date)
                                    if (destIndex){
                                        const valueToPush = updatedDatesArray[i].dayData[j]
                                        // place it to correct location
                                        updatedDatesArray[destIndex].dayData.splice(j, 0, valueToPush)
                                        // move it from the source to destination
                                        updatedDatesArray[i].dayData.splice(j, 1)
                                    }
                                
                                }
                            }


                        }

                        if (!isAtCorrectLocation && typeof targetPositionIndex !== 'undefined' && typeof sourceColumnIndex !== 'undefined' && typeof sourcePositionIndex !== 'undefined') {
                            const valueToPush = updatedDatesArray[sourcePositionIndex].dayData[sourceColumnIndex]
                            // place it to correct location
                            updatedDatesArray[targetPositionIndex].dayData.splice(sourceColumnIndex, 0, valueToPush)
                            // move it from the source to destination
                            updatedDatesArray[sourcePositionIndex].dayData.splice(sourceColumnIndex, 1)
                        }

                        // check if the process is not at the correct position in the dates array
                 
                        this.setState({ datesArray: updatedDatesArray })
                    }
                    this.setState({loader: false})
                    return;
                } else this.setState({datesArray: this.state.prevState});
                this.updateConstantToSpred(false); 
            }
            if (end_dateChanged) {
                let {_id: id} = res.result || {};
                let val = id ? {...newBody, _id: id} : {...newBody};
                let spread = this.state.updateApiBody.process.constant ? false : spreading               
                await setEndDateForProcess(val, spread).then(res2 => {
                    res = res2;
                }).catch(err => console.error(err));
                this.setState({end_dateChanged: false})
            }

            if (res.ok) {
                this.setState({ loader: false })
                // this.initializeData();
                // this.setState({ counter: 0, orders: [], stopApi: false, from: null, to: null});
                this.props.setCsvMonthly(this.state.datesArray);
            } else {
                this.setState({ errPopupState: res.result , loader: false, popup: !is_same_date || res.result });
            }
            this.setState({prevState: [], prevCompletionData: [], spreading: spreading && false});
        } else {
            let prevState = this.state.prevState.map(item => item)

            if (!prevState || prevState.length === 0)
                prevState = JSON.parse(JSON.stringify(this.state.datesArray))
            this.setState({ datesArray: prevState, popup: false, errPopupState: null, loader: false, spreading: spreading && false })
        }
        if(param == 'no')await this.initializeData();
    }


    sanitizeDraggableId = (dndData) => {
        try {
            const {
                draggableId,
            } = dndData;
    
            if (draggableId.includes('_')) {
                return draggableId.split('_')[0];
            }
            return draggableId;
        } catch (err) {
            return dndData.draggableId
        }
    }


    onDragEnd = async (dndData) => {
        store.dispatch(setDraggingDestination(null));

        dndData.draggableId = this.sanitizeDraggableId(dndData)
        this.updateOpacity(1,dndData.draggableId)
        let { droppableId: destinationDropableId } = dndData.destination || {};
        let { droppableId: sourceDropableId } = dndData.source || {};
        // if (destinationDropableId.inlcudes('_')) {
            // destinationDropableId = destinationDropableId.split('_')[0]
        // }

        // if (sourceDropableId.inlcudes('_')) {
            // sourceDropableId = sourceDropableId.split('_')[0]
        // }
        if(!destinationDropableId || !sourceDropableId ) return;
        if (!dndData.destination || (dndData.source.droppableId === dndData.destination.droppableId)) return;
        const getColumnNumber = (colId) => colId.split('-')[1]
        const destinationColNumber = getColumnNumber(dndData.destination.droppableId)
        const sourceColNumber = getColumnNumber(dndData.source.droppableId)
        const privileges = this.props.login.user.privileges
        const isViewOnlyOrPerUser = privileges.find(privilege => privilege === VIEW_ONLY)
        if (destinationColNumber > sourceColNumber && isViewOnlyOrPerUser) {
            return
        }
        const { datesArray, completionData } = this.state
        
        //clone original array and making date obj to moment obj back again (was modified in json parse)
        let datesArrayCopy = JSON.parse(JSON.stringify(datesArray));
        let _completion = JSON.parse(JSON.stringify(completionData));
        this.setState({ loader: true });
        let destination_date = moment(dndData.destination.droppableId, 'DD-MM-YYYY').isValid() ? moment(dndData.destination.droppableId, 'DD-MM-YYYY')._d : moment(dndData.source.droppableId, 'DD-MM-YYYY')._d;
        if (dndData.source.droppableId === 'completions__droppable') {
            const dndDataDraggableIdOfCompletionsDroppable = dndData.draggableId.slice(0, -2)
            if (dndData.draggableId.endsWith("x1")) {
                dndData.draggableId = dndData.draggableId.slice(0, -2);
            }
            let current_process = (_completion.find(_i => _i.proccess._id === dndData.draggableId))
            if (!current_process) {
                //couldnot find the dnd process in completions
                return;
            }
            current_process = current_process.proccess;
            _completion = _completion.filter(_i => _i.proccess._id !== dndData.draggableId) || {};
            current_process.backlog = false;
            let _destination = dndData.destination.droppableId;
            let body = {
                order_id: current_process.order_id,
                _id: dndDataDraggableIdOfCompletionsDroppable,
                date: destination_date,
                from: moment(this.state.currentDate)._d,
                to: moment(this.state.currentDate).add(28, 'days')._d,
                view: 'L',
                process: current_process,
            };
            datesArrayCopy.map((item, indexInDay) => {
                if(item.parsedDate === _destination){
                    item.dayData.push({
                        client_name: current_process.client_name,
                        due_date: current_process.due_date,
                        order_id: current_process.order_id,
                        order_number: current_process.order_number,
                        proccess: {...current_process},
                        warnings: current_process.warnings
                    });
                    // item.dayData.sort((a, b) => a.proccess.original_duration - b.proccess.original_duration);
                }
            })
            this.setState({
                prevState: this.state.datesArray,
                prevCompletionData: this.state.completionData,
                spreadingBodyData: body,
                datesArray: datesArrayCopy,
                completionData: _completion,
                updateApiBody: {
                    ...body,
                }
            }, async () => {
                let diff = moment(moment(body.date).set({ hour: 14, minute: 0, second: 0 }).toDate()).diff(moment(body.process.process_date).set({ hour: 14, minute: 0, second: 0 }).toDate(), 'days')
                let end_date = moment(body.process.process_endDate).set({hour: 14, minute: 0, second: 0}).add(diff,'day').toDate();
                if (this.props.login.popupActivation && !body.process.constant) {
                    if ((isWeekChange(body.process.process_date, body.date)
                        || 
                        isWeekChange(body.process.process_endDate, end_date, diff > 0 ? 1 : -1)) && !body.process.is_detached) {
                        this.setState({ spreadingPopup: true, loader: false });
                    } else {
                        this.spreadingFalse();
                    }
                }else {
                    if (body.process.constant) {
                        this.props.updateConstantPopup(true)
                        this.setState({ loader: false, spreadingBodyData: body })
                    } else {
                        this.setState({ spreadingPopup: true, spreadingBodyData: body, loader: false })
                    }
                }
            })
            return
        }
 
        //search source in dataArray (fit to explorer)
        let daySrcIndex, dayDestIndex
        for (let i = 0; i < datesArrayCopy.length; i++) {
            if (datesArrayCopy[i].parsedDate === dndData.source.droppableId) {
                daySrcIndex = i
                if (daySrcIndex && dayDestIndex) break;
            }

            if (datesArrayCopy[i].parsedDate === dndData.destination.droppableId) {
                dayDestIndex = i
                if (daySrcIndex && dayDestIndex) break;
            }
        }


        // create body to send to API
       
        let main_process = datesArrayCopy.map(item => item.dayData.filter(subitem => {
                                    return subitem.proccess._id === dndData.draggableId
                              })).filter(item => item.length > 0)[0][0];
        let current_process = main_process.proccess;
        // main process is the dayData Obj of the dragged process
        // current process is the process key object inside the main process
       
        let body = {
            order_id: current_process.order_id,
            _id: dndData.draggableId,
            date: destination_date,
            from: moment(this.state.currentDate)._d,
            to: moment(this.state.currentDate).add(28, 'days')._d,
            view: 'L',
            toBacklog: dndData.destination.droppableId === 'completions__droppable' ? true : false,
            process: current_process,
        }


        //search order_id in case source index is in completions
        if (!body.order_id)
        datesArrayCopy.map(day => {
            day.dayData.map(proccess => {
                if (proccess.proccess._id === dndData.draggableId)
                    body.order_id = proccess.order_id
            })
        })
        this.setState({ updateApiBody: body })
        
        //handle situation when deastination of drag element in completions (to completions)
        if (dndData.destination.droppableId === 'completions__droppable') {
            let originalId;
            let { order_id:o_id, process_id:p_id , _id} = current_process
            let lastIndex = daySrcIndex + (current_process.original_duration || 0)
            while(daySrcIndex < datesArrayCopy.length){
                datesArrayCopy[daySrcIndex].dayData = datesArrayCopy[daySrcIndex]?.dayData.filter((item) => {
                    let {order_id, process_id} = item.proccess;
                    if(o_id === order_id && p_id === process_id){
                        o_id = order_id; p_id = process_id;
                        item.proccess.backlog = true;
                        originalId = item.proccess.hasOwnProperty('original') ? item.proccess.original : item.proccess._id;
                        if (originalId) {
                            item.proccess._id = originalId
                        }
                    }else return item;
                }) || [];
                daySrcIndex++;
            }
            _completion.filter(i => i.proccess._id === main_process.proccess._id).length === 0 && _completion.push({...main_process})
            _completion.sort((a,b) => new Date(a.proccess.process_date).getTime() - new Date(b.proccess.process_date).getTime());
            this.setState({ 
                datesArray: datesArrayCopy,
                completionData: _completion,
                tempIdRelatedToComp: _id,
                prevState: this.state.datesArray }, async() => {
                this.handlePopupChoice('yes',)
            })

            return
        }

        // get item to push to new state
        let valueToPush = datesArrayCopy[daySrcIndex].dayData.filter((item, index) => {
            return dndData.source.index === index
        })[0]
        //push item to its new place 
        datesArrayCopy[dayDestIndex].dayData.splice(dndData.destination.index, 0, valueToPush)
        //remove item from source in array in order to push it to its new location according to DND
        const processToMove =  datesArrayCopy[daySrcIndex].dayData[dndData.source.index]
        datesArrayCopy[daySrcIndex].dayData.splice(dndData.source.index, 1)
        if (processToMove && ('brokenProcessIndex' in processToMove.proccess)) {
            let ogProcessColIndex;
            let ogProcessColRowIndex;

            for (let i=0; i < datesArrayCopy.length; i++) {
                for (let j=0; j < datesArrayCopy[i].dayData.length; j++) {
                    if (processToMove.proccess._id === datesArrayCopy[i].dayData[j].proccess._id && !('brokenProcessIndex' in datesArrayCopy[i].dayData[j].proccess)) {
                        ogProcessColIndex = i;
                        ogProcessColRowIndex = j;
                        break;
                    }
                }
            }

            if (ogProcessColIndex && ogProcessColRowIndex) {
                const process  =datesArrayCopy[ogProcessColIndex].dayData[ogProcessColRowIndex]
                datesArrayCopy[dayDestIndex].dayData.splice(dndData.destination.index, 0, process)
                datesArrayCopy[ogProcessColIndex].dayData.splice(ogProcessColRowIndex, 1)
            }
        }

        this.setState({
            prevState: this.state.datesArray,
            spreadingBodyData: body,
        }, () => {
            this.setState({ datesArray: datesArrayCopy })
        })

        let diff = moment(moment(body.date).set({ hour: 14, minute: 0, second: 0 }).toDate()).diff(moment(body.process.process_date).set({ hour: 14, minute: 0, second: 0 }).toDate(), 'days')
        let end_date = moment(body.process.process_endDate).set({hour: 14, minute: 0, second: 0}).add(diff,'day').toDate();
        if (this.props.login.popupActivation && !body.process.constant) {

            if (    (isWeekChange(body.process.process_date, body.date)
                    || 
                    isWeekChange(body.process.process_endDate, end_date, diff > 0 ? 1 : -1)) 
                    && !body.process.is_detached
                ) {
       
                this.setState({ spreadingPopup: true, loader: false });
            } else {
     
                this.spreadingFalse();
            }
        } else {
            //check for warnings

            if (body.process.constant) {

                this.props.updateConstantPopup(true)
                this.setState({ loader: false, spreadingBodyData: body })
            } else {

                this.setState({ spreadingPopup: true, spreadingBodyData: body, loader: false })
            }
        }


    }

    onDragUpdate = (update) => {
        const { destination } = update;
        store.dispatch(setDraggingDestination(destination))
    };


    //new for constant change - 
    resetReposition = () => {
        this.setState({ popup: true })
    }

    // spreadingFalse calls handlepopup
    spreadingFalse = async () => {
        this.setState({ loader: true, spreadingPopup: false })
        let body = { ...this.state.spreadingBodyData }
        let warningsApi = await getWarnings(body)

        if (warningsApi.ok) {
            if (warningsApi.result.length > 0) {
                this.setState({ popup: true, loader: false, warnings: warningsApi.result, spreadingPopup: false })
            } else {
                this.handlePopupChoice('yes')
            }
        } else {
            this.setState({ popup: true, loader: false, errPopupState: warningsApi.result, spreadingPopup: false })
        }
    }

    spreadingTrue = async () => {
        this.setState({spreadingPopup: false})
        const { spreadingBodyData } = this.state
        let warningsApi = await getWarnings(spreadingBodyData, true)
        if (warningsApi.ok) {
            if (warningsApi && warningsApi.result && warningsApi.result.length > 0) {
                this.setState({ popup: true, loader: false, warnings: warningsApi.result })
            } else {
                this.setState({
                    spreading: true
                },() => {
                    if (this.props.login.user.reason_popup) {
                        this.updateWarningPopup(true)
                    }else{
                        this.handlePopupChoice('yes', false)
                    }
                })
            }
        } else {
            this.setState({ popup: true, loader: false, errPopupState: warningsApi.result })
        }
    }

    handleSpreadWithReason = async (data) => {

        let body = { ...this.state.spreadingBodyData }
        body.reason = data.reason.value
        body.approved_by = data.approveName.value
        //check for warnings

        let warningsApi = await getWarnings(body, true)
        if (warningsApi.ok) {
            if (warningsApi && warningsApi.result && warningsApi.result.length > 0) {
                this.setState({ popup: true, loader: false, warnings: warningsApi.result, reasonPopup: false })
            } else {
                this.setState({ updateApiBody: body, reasonPopup: false, spreading: true }, () => {
                    this.handlePopupChoice('yes', false)
                })
                // }
            }

        } else {
            this.setState({ popup: true, loader: false, errPopupState: warningsApi.result, reasonPopup: false })
        }

    }

    cancelReasonPopup = () => {
        let datesArrayCopy = JSON.parse(JSON.stringify(this.state.prevState))
        this.setState({ reasonPopup: false, datesArray: datesArrayCopy })
    }

    closeUpdateMenu = () => {
        this.setState({
            updateProcessPopup: false,
            selectedProcess: {},
        })
    }

    submitUpdatesFromPopup = async (body, constant, refetchData, newProcess = null) => {
        if (
            moment(body.date).isSame(moment(body.process.process_date)) && 
            !body.endDate
        ) {
            this.setState({updateProcessPopup: false, } , async () => {
                // this.initializeData()
                this.setState({
                    loader: false,
                })
            });return;
        }
        const { end_dateChanged } = body;

        if(end_dateChanged) delete body.end_dateChanged;
        let diff = moment(moment(body.date).set({ hour: 14, minute: 0, second: 0 }).toDate()).diff(moment(body.process.process_date).set({ hour: 14, minute: 0, second: 0 }).toDate(), 'days')
        let end_date = moment(body.process.process_endDate).set({hour: 14, minute: 0, second: 0}).add(diff,'day').toDate();
        this.setState({spreadingBodyData: body, updateApiBody: body, updateProcessPopup: false,end_dateChanged } , async () => {
            if(body.process.constant){
                let warningRes = await getWarnings(body);
                if (warningRes.ok && warningRes.result.length <= 0) {
                    this.handlePopupChoice('yes')
                } else {
                    this.setState({errPopupState: warningRes.result , popup: true,});
                } 
            }else {
                if (body.endDate && !body?.process?.is_detached && isWeekChange(body?.process?.process_endDate, body.endDate)
                ||
                isWeekChange(body.process.process_endDate, end_date, diff > 0 ? 1 : -1)) {
                    this.setState({ spreadingPopup: true});
                } else {
                    this.spreadingFalse();
                }
            }
        });


        this.setState({
            loader: false,
        })
    }

    updateDoneApiResponse = (response, order_process_id) => {
        if (response && response.ok && (typeof response.result === 'undefined' || (typeof response.result == 'boolean' && response.result))) {
            const updatedDatesArray = this.state.datesArray.map((dateObj) => {
                return {
                    ...dateObj,
                    dayData: dateObj.dayData.map((dayData) => {
                        if (dayData.proccess._id === order_process_id) {
                            return {
                                ...dayData,
                                proccess: {
                                    ...dayData.proccess,
                                    done: true,
                                },
                                done: true,
                            }
                        }
                        return dayData
                    })
                }
            })
            
            this.setState({
                datesArray: updatedDatesArray,
            });
        }
    }

    updateDraggableAfterUpdate = (response) => {
        const {
            ok,
            result,
        } = response;

        // if its not succes we are not updating the object right now
        if (!ok) {
            return;
        }

        const {
            _id: orderProcessIdOfUpdatedDraggable,
            resource,
            process_date,
            remark,
            done, 
            warnings,
            fraction,
            process_name,
            is_detached,
            backlog,
            should_occur_parallel,
        } = result;

        const updatedDatesArray = this.state.datesArray.map((dateObj) => {
            const colors_by_users = resource.map((_res) => _res.color)
            return {
                ...dateObj,
                dayData: dateObj.dayData.map((dayData) => {
                    if (dayData.proccess._id === orderProcessIdOfUpdatedDraggable) {
                        const isProcessCompletedNow = !!(!dayData.proccess.backlog && backlog)
                        return {
                            ...dayData,
                            proccess: {
                                ...dayData.proccess,
                                resource: resource.map((res) => {
                                    return {
                                        ...res,
                                        resource_id: res._id,
                                        name: res.full_name,
                                        user_for_resource_id: res._id
                                    }
                                }),
                                process_date,
                                remark,
                                done, 
                                // warnings,
                                fraction,
                                process_name,
                                colors_by_users,
                                is_detached,
                                should_occur_parallel,
                                isProcessCompletedNow: isProcessCompletedNow,
                            }
                        }
                    }
                    return dayData
                })
            }
        })


        this.setState({
            datesArray: []
        }, ()=> {
            this.setState({
                datesArray: updatedDatesArray,
            });
        })
  

    }

    initProccessUpdatePopup = (data, date) => {
        let body = {
            from: moment(this.state.currentDate)._d,
            to: moment(this.state.currentDate).add(28, 'days')._d,
            view: "L",
            toBacklog: false,
            order_id: data.order_id,
            _id: data.proccess._id,
            process: data.proccess,
            date
        }
        this.setState({
            selectedProcess: data
        }, () => {
            this.setState({ updateProcessPopup: true })
        })

    }

    saveNewSelectedProcess = (process) => {
        let selectedProcess = { ...this.state.selectedProcess }
        selectedProcess.proccess = { ...process }
        this.setState({
            selectedProcess
        })
    }

    cancleSpreading = () => {
        let prevState = JSON.parse(JSON.stringify(this.state.prevState))
        let prevComp = JSON.parse(JSON.stringify(this.state.prevCompletionData))
        if(prevComp && prevComp.length > 0)this.setState({ completionData: prevComp});
        if (prevState && prevState.length > 0)this.setState({ datesArray: prevState })
        this.setState({ spreadingPopup: false });
        this.props.updateConstantPopup(false);
    }

    backToToday = () => {
        this.setState({ stopApi: false, orders: [], from: false, to: false, counter: 0 })
        // dynamic factory name in url 
        const dynamicFactoryName = this.props.login.user.factory_name
        let search = window.location.search
        this.props.history.push(`/${dynamicFactoryName}/monthly/${moment().toDate()}${search ? search : ''}`)
        this.setState({ currentDate: moment().startOf('week') }, () => {
            this.initializeData()
        })
    }

    updateWarningPopup = (value) => this.setState({popup: value})

    onBeforeCapture = ({ draggableId }) => {

    }

    updateOpacity = (opacity, draggableId) => {
       
    }

    getMoreDataOnScroll = () => {
        
    }

    afterUpdateOrderProcess = async (orderNumber) => {
        this.setState({ loader: true });
        const from = moment().startOf('year');;
        const to = moment().endOf('year');
        let selectedDepartment = this.props.login.selectedDepartment._id
        let employee_id = this.props.login.selectedManager._id || '';
        let started = this.props.process.show_bids
        const user_id = jwt_decode(this.props.login.user.token)._id
        const selectedUser = this.props.login.user.privileges.includes(PER_USER) ? user_id : this.props.login.selectedUser;
        let current_process = this.props.process.show_first_uncomplete_process;
        const ordersData = await (appGetOrders(from._d, to._d, 'L', orderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process))
        
        let _datesArray = this.updateDatesArray(ordersData);
        this.setState({ datesArray: [] }, () => {
            this.setState({
                datesArray:  _datesArray,
                forCacheData: _datesArray,
                loader: false,
            })
        })
        
 
        setTimeout(() => this.props.setCsvMonthly(_datesArray),1)
    }

    updateDatesArray = (_orders) => {
        const obj =  this.state.datesArray.map(m => ({
            ...m,
            dayData: m.dayData.map(d => {
                const matchingOrder = _orders.find(order => 
                    order.processes.some(process => process._id === d.proccess._id)
                );
                if (matchingOrder) {
                    const  currentProcess = matchingOrder.processes.find(process => process._id === d.proccess._id);
                    return { 
                        ...d, 
                        client_name: currentProcess.client_name,
                        due_date: currentProcess.due_date,
                        order_id: currentProcess.order_id,
                        order_number: currentProcess.order_number,
                        proccess: {...currentProcess},
                        warnings: currentProcess.warnings 
                    }; // Update color if match is found
                }
                return d; // Return original data if no match
            }),
        }));
        return obj;
    }

    handleScroll = (event) => {
        const {
            scrollHeight,
            scrollTop,
            clientHeight,
        }  = event.target;
        if (scrollTop === 0 && !this.state.loader && scrollHeight >= 300 && clientHeight >= 300) {
        // if (false) {
            this.loadMoreMonthlyViewData(false)
        } else if ((scrollTop + clientHeight >=  scrollHeight - 50) && !this.state.loader && scrollHeight >= 300 && clientHeight >= 300) {
            this.loadMoreMonthlyViewData(true)
        }
    }

    addMoreCompletion = async() => {    
        let limit = 50;
        let {completionPage , completionData, completionCount, tempIdRelatedToComp} = this.state;
        if(limit + completionPage >= completionCount)return true;
        let selectedDepartment = this.props.login.selectedDepartment._id;
        let urlOrderNumber = window.location.search.replace('?order_number=', '');
        let employee_id = this.props.login.selectedManager._id;
        const user_id = jwt_decode(this.props.login.user.token)._id
        const selectedUser = this.props.login.user.privileges.includes(PER_USER) ? user_id : this.props.login.selectedUser;
        let current_process = this.props.process.show_first_uncomplete_process;
        this.setState({completionPage: limit + completionPage, loader: true});
        return getCompletion(limit, limit + completionPage, 'L', selectedDepartment, urlOrderNumber, employee_id, selectedUser, current_process).then(res => {
            let {data=[], count} = res.result;
            let filterCompletion = tempIdRelatedToComp ? completionData.filter(_i => _i.proccess._id !== tempIdRelatedToComp) : completionData;
            data = [...filterCompletion,...data].sort((a,b) => {
                let first = new Date(a.proccess.process_date).getTime();
                let second = new Date(b.proccess.process_date).getTime();
                if(first === second)return a.proccess.order - b.proccess.order;
                else return first - second;
            });
            this.setState({
                completionData:data,
                tempIdRelatedToComp:null,
                loader: false
            })
            return data;
        }).catch(error => {console.error('completion api failed!')});
    }

    loadMoreFunction = () => {
        this.loadMoreMonthlyViewData(true);
    }

    getCompletionProcessDataFromCompletionApi = async (order_number, orderProcessId, process) => {
        const getUpdatedProcessResponse = await getCompletion(1, 0, 'L', this.props.login.selectedDepartment._id, order_number, this.props.login.selectedManager._id, this.props.login.user.privileges.includes(PER_USER) ? jwt_decode(this.props.login.user.token)._id : this.props.login.selectedUser, false, orderProcessId);
        const { ok,result} = getUpdatedProcessResponse;
       const {data} = result;

        const selectProcessToUpdate = data.find((_process) => _process.proccess._id === orderProcessId);

        const startDate = moment(selectProcessToUpdate.proccess.process_date).startOf('day')
        const endDate = moment(selectProcessToUpdate.proccess.process_endDate).startOf('day')

       const process_width =  endDate.diff(startDate, 'days') + 1;
       const updateWidthFromSelectedProcess = {
           ...selectProcessToUpdate,
              proccess : {
                  ...selectProcessToUpdate.proccess,
                  process_width: process_width,
              }
        }

        const updateWidthFromSelectedProcess2 = {
               ...process,
               proccess : {
                   ...process.proccess,
                   process_width: process_width,
               }
        }

        return updateWidthFromSelectedProcess2;

   }
    updateCompletionDataWhenCompletionCheck = async (action, processToCompletion) => {
        if (action === 'add'){
            this.setState((prevState) => ({ ...prevState, completionData: [...prevState.completionData, processToCompletion] }))
        }else{
            this.setState({
                completionData: this.state.completionData.filter((_process) => _process.proccess._id !== processToCompletion.proccess._id)
            }) 
            const completionProcessResp = await this.getCompletionProcessDataFromCompletionApi(processToCompletion.proccess.order_number, processToCompletion.proccess._id, processToCompletion);
            const toPlaceIndex = this.findDateIndexInDatesArray(this.state.datesArray, processToCompletion.proccess.process_date)
            const updatedDatesArray = this.state.datesArray.map((dateObj, index) => {
                if (index === toPlaceIndex) {
                        return {
                            ...dateObj,
                            dayData: [...dateObj.dayData, completionProcessResp]
                        }
                } 
                return {
                    ...dateObj,
                }
            })
           

            this.setState({
                datesArray: updatedDatesArray,
            });
        }
    }


    render() {
        const { currentDate,
            orders,
            datesArray,
            popup,
            errPopupState,
            updateProcessPopup,
            selectedProcess,
            loader,
            warnings,
            spreadingPopup,
            counter, 
            simpleDateArray,
            completionData,
        } = this.state;
        const { off_days, holidays, popupActivation } = this.props.login.user
        let disableDrag = this.props.login && Object.keys(this.props.login.user).length > 0 && this.props.login.user.privileges.includes(PER_USER) ? true : false
        const dynamicFactoryName = this.props.login.user.factory_name;
        const user_or_order_type = this.props.login.user.select_order_process_color_by;

        return (
            <Fragment>
                <div className="month-view-page">

                    {spreadingPopup ?
                        <SpreadingPopup
                            spreadingFalse={this.spreadingFalse}
                            spreadingTrue={this.spreadingTrue}
                            cancleSpreading={this.cancleSpreading}
                        />
                        :
                        null
                    }
                    {updateProcessPopup ?
                        <UpdateProccessPopup
                            warningApiPayload={this.state.updateApiBody}
                            selectedProcess={selectedProcess}
                            view={'L'}
                            closeUpdateMenu={this.closeUpdateMenu}
                            submitUpdatesFromPopup={this.submitUpdatesFromPopup}
                            offDays={off_days}
                            holidays={holidays}
                            saveNewSelectedProcess={this.saveNewSelectedProcess}
                            deleteBacklogsDuplications={this.deleteBacklogsDuplications}
                            updateUpdateApiResponse={this.updateDraggableAfterUpdate}
                            updateDoneApiResponse={this.updateDoneApiResponse}
                            updateCompletionDataWhenCompletionCheck={this.updateCompletionDataWhenCompletionCheck}
                        />
                        :
                        null
                    }

                    {popup ?
                        <OnDragPopup
                            afterConfirmationError={errPopupState}
                            popupActionHandler={this.handlePopupChoice}
                            warningApiPayload={this.state.updateApiBody}
                            warnings={warnings}
                            user_or_order_type={user_or_order_type}
                        />
                        :
                        null
                    }

                    {this.props.orders.constant_popup ?

                        <ConstantPopup
                            updateConstantToSpred={this.updateConstantToSpred}
                            resetReposition={this.resetReposition}
                            spreadingFalse={this.spreadingFalse}
                            spreadingTrue={this.spreadingTrue}
                            cancleSpreading={this.cancleSpreading}
                            popupActionHandler={this.handlePopupChoice}
                            handleWarningPopup = {this.updateWarningPopup}
                        />
                        :
                        null
                    }

                    <DragDropContext 
                        onDragEnd={this.onDragEnd} 
                        onBeforeCapture={this.onBeforeCapture}
                        onDragUpdate={this.onDragUpdate}
                    >

                        <Calendar
                            initProccessUpdatePopup={this.initProccessUpdatePopup}
                            datesArray={datesArray}
                            checkIfSameDay={this.checkIfSameDay}
                            currentSelectedDate={currentDate}
                            buildDatesArray={this.buildDatesArray}
                            fourWeeksDateChange={this.fourWeeksDateChange}
                            orders={orders}
                            loader={loader}
                            daysOffNumber={off_days ? off_days.length : 0}
                            daysOff={off_days}
                            disableDrag={false}
                            backToToday={this.backToToday}
                            isCurrentProcess={this.props.process.show_first_uncomplete_process}
                            factoryName={dynamicFactoryName}
                            weeksLoader={counter}
                            userData={this.props.login.user}
                            simpleDateArray={simpleDateArray}
                            user_or_order_type={user_or_order_type}
                            getMoreDataOnScroll={onScroll(this.getMoreDataOnScroll)}
                            loadMore={this.loadMoreFunction}
                            hasMoreData={this.state.hasMoreData}
                            loading={this.state.loading}
                            handleScroll={this.handleScroll}
                            apiStart={this.state.apiDataLastStart}
                            apiEnd={this.state.apiDataLastEnd}
                        />

                        <Completions
                            initProccessUpdatePopup={this.initProccessUpdatePopup}
                            completionArrays={completionData}
                            buildDatesArray={this.buildDatesArray}
                            orders={orders}
                            daysOffNumber={off_days ? off_days.length : 0}
                            addMoreCompletion={onScroll(this.addMoreCompletion)}
                        />
                    </DragDropContext>

                </div>

                {
                    loader && <Loader />
                }

            </Fragment>
        )
    }

}


function mapStateToProps({ login, process, orders, mainPopup }) {
    return { login, process, orders, mainPopup }
}
export default withRouter(connect(mapStateToProps, actions)(MonthView))
