import { Draggable } from 'react-beautiful-dnd';
import React, {  } from "react";
import drag_icon from '../../../../../images/icons/drag_x.svg'
import { InformationPopup } from '../../../../updatePopups/InformationPopup';
import {createOrderProcessBackground} from '../../../../../functions/helpers/createOrderProcessBackground'
import { getPosition, updateClass } from '../../../../../hooks/helper';
import Lock from '../../../../../images/updatepopup/openLock.svg'
import NotParallelProcessIcon from '../../../../../images/updatepopup/notParallel.svg'
import { useSelector } from 'react-redux';


const CalendarDraggable = React.memo((props) => {
    const {
        data,
        index,
        disableDrag,
        day_index,
        day,
        user_or_order_type,
        handleProcessMenu,
        checkIndexAt,
        checkIndexAndSetWidth,
        closeHandlerInfoPopup,
        resourcesString,
        info_popup_position,
        initHandlerForInfoPopup,
    } = props;

    const draggingDestination = useSelector((state) => state.generalReducers.draggingDestination);

    const getDraggaleId = (processObj) => {
        // !data.proccess.skipForChekedinCheck ? data.proccess._id : `${data.proccess._id}_${data.proccess.dummyIndex}`
        const {
            skipForChekedinCheck,
        } = processObj
        // normal

        // id for fixed dummy process
        if (skipForChekedinCheck) {
            return `${data.proccess._id}_${data.proccess.brokenProcessIndex}`
        }

        if (data.proccess.dummy) {
            return `${data.proccess._id}_dummy` 
        }

        // id for the broken process into holidays

        return data.proccess._id;
    }

    return <Draggable draggableId={getDraggaleId(data.proccess)} index={index} key={!data.proccess.skipForChekedinCheck ? data.proccess._id : `${data.proccess._id}_${data.proccess.dummyIndex}`} isDragDisabled={disableDrag || data.proccess.done}>

        {(provided, snapshot) =>{
            if (snapshot.isDragging && draggingDestination?.droppableId === 'completions__droppable') {
                return <React.Fragment>
                        <section 
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            id={`${data.proccess._id}x1y2`}
                            className="month-view-page__completions__draggable" 
                            style={{
                                ...provided.draggableProps.style,
                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                padding: '20px 10px',
                                background: 'rgba(36, 55, 72, 0.25)'
                            }}
                        >
                            <div>{data.proccess.order_number} - {data.proccess.client_name}</div>
                            <div>{data.proccess.process_name}</div>
                            <div>הושלמו {data.proccess.finished} מתוך {data.proccess.quantity}</div>
                        </section>
                </React.Fragment>
            }
            return (
           <React.Fragment>
                <div
                    onClick={() => { handleProcessMenu(data, day.date) }}
                    className={data?.proccess?.warnings ? `month-view-page__calendar__table__day__process  month-view-page__calendar__table__day__process_css2 ${updateClass(data.proccess)}` : `month-view-page__calendar__table__day__process month-view-page__calendar__table__day__process_css2 ${updateClass(data.proccess)}`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    style={{
                        cursor: data.proccess.fraction ? 'pointer' : '',
                        zIndex: checkIndexAt(day.parsedDate, data) ? '99' : '0',
                        backgroundColor:  data.proccess.done ? '#E8E9EB' : '',
                        background: createOrderProcessBackground(data?.proccess, user_or_order_type),
                        borderRadius: '20px',
                        width: checkIndexAndSetWidth(day_index, data),
                        marginRight: '5px',
                        marginBottom: '5px',
                        ...provided.draggableProps.style,
                    }}
                    id={`${data.proccess._id}x1y2`}
                >

                    <div className="drag__icon__container" onMouseEnter={(event) => initHandlerForInfoPopup(event, data.proccess._id)} onMouseLeave={(event) => closeHandlerInfoPopup(event, data.proccess._id)}>
                        {" "}
                        <img className="drag__icon" src={drag_icon} alt="drag icon"></img>
                    </div>
                    <section style={{ padding: '0px 10px' }}>
                        <div>
                            <p>
                                {/* logging width temporarily */}
                                {/* {data?.proccess?.process_width}  */}
                                {/* {data?.proccess?.brokenProcessIndex ? `BBB_${data?.process?.brokenProcessIndex}` : null } */}
                                <span className="month__process__client__name">
                                    {data?.proccess?.client_name}
                                </span>
                                {data?.proccess?.remark ? '-' : ''}
                                <span className="month__process__client__name four_characters_length">
                                    {data?.proccess?.remark} -
                                </span>
                                <span className="month__process__number">
                                    {data?.proccess?.order_number}
                                </span>
                                <span className="icones"></span>
                            </p>
                        </div>
                        <div tooltip={resourcesString}><p>{data?.proccess?.process_name} -<span className="month__proccess__employee"> {resourcesString}</span></p></div>
                    </section>
                    {
                        data?.proccess?.is_detached ?
                            <div className="month-view-page__calendar__table__day__process--menu"><img src={Lock} alt="menu" /></div>
                            :
                            null
                    }
                    {
                        !data?.proccess?.is_detached  && !data?.proccess?.should_occur_parallel  ? 
                          <img style={!data?.proccess?.should_occur_parallel ? {marginBottom:'2px', transform: 'rotate(90deg)', position: 'absolute', left: '10px'}:{}} className="lock__icon" src={NotParallelProcessIcon} alt="lock icon"></img>
                        :null 
                    }
                </div>
                <div style={{ display: 'none', transform: `translateX(${getPosition(info_popup_position, data.proccess.process_date)}%)` }} className="info--popup" id={data.proccess._id}>
                    <InformationPopup
                        process={data.proccess}
                        popupId={`${data.proccess._id}`}
                    />
                </div>
            </React.Fragment>
        )}}
    </Draggable>
})


export default CalendarDraggable;
