var prevData = [];
var previousData = [];

export const sortProcessesWeeklyView = (data=[]) => {

    const doneProcesses  = data.filter(i=>i.done || i.quantity === i.finished)
    const activeProcesses = data.filter(i => !i.done && i.quantity !== i.finished && !i?.start_date)
    // const sortedActiveProcesses = activeProcesses.sort((a,b)=> a.original_process_date < b.original_process_date ? -1 : a.original_process_date === b.original_process_date ? 0 : 1)
    const moreThanOneDayProcesses = data.filter(i =>  !i.done && i.quantity !== i.finished && i?.start_date) 
    const sortedMoreThanOneDayProcesses = moreThanOneDayProcesses.sort((a,b)=> a.start_date < b.start_date ? -1 : a.start_date === b.start_date ? 0 : 1) 
    return [...sortedMoreThanOneDayProcesses, ...activeProcesses, ...doneProcesses]

}
 

export const sortProcessesMonthlyView = (data=[]) => {
    return data
}


export const sortProcessesMonthlyRView = (date = "", data=[]) => {
    // console.log("date: ", date)
    let doneProcesses = []
    data.filter((x, xIndex) => {
        let dataValue = []
        if(x !== undefined) {
            if(x.value && x.value.length > 0) {
                x.value.filter(i => { 
                    if(i?.proccess?.done || i?.proccess?.quantity === i?.proccess?.finished) { 
                        dataValue.push(i)
                    } 
                })
            }
            doneProcesses.push({ key: x.key, value: dataValue, heightWouldBe: x.heightWouldBe , user_name: x.user_name, overloaded: x.overloaded})
        }
    })
    // console.log("doneProcesses: ", doneProcesses)

    let activeProcesses = []
    data.filter((x, xIndex) => {
        let dataValue = []
        if(x !== undefined) {
            if(x.value && x.value.length > 0) {
                x.value.filter(i => { 
                    if(!i?.proccess?.done && i?.proccess?.quantity !== i?.proccess?.finished && !i?.proccess?.start_date) { 
                        dataValue.push(i)
                    } 
                })
            }
            activeProcesses.push({ key: x.key, value: dataValue, heightWouldBe: x.heightWouldBe , user_name: x.user_name, overloaded: x.overloaded})
        }
    })

    let currentData = [...doneProcesses]

    activeProcesses.map((activeP) => {
        currentData.map((cData) => {
            if(activeP.key === cData.key) {
                cData.value = [...cData.value, ...activeP.value]
            }
        })
    })
    

    // let moreThanOneDayProcesses = []
    // data.filter((x, xIndex) => {
    //     let dataValue = []
    //     if(x.value && x.value.length > 0) {
    //         x.value.filter(i => { 
    //             if(!i?.proccess?.done && i?.proccess?.quantity !== i?.proccess?.finished && !i?.proccess?.start_date) { 
    //                 dataValue.push(i)
    //             } 
    //         })
    //     }
    //     moreThanOneDayProcesses.push({ key: x.key, value: dataValue, heightWouldBe: x.heightWouldBe })
    // })

    // let sortedMoreThanOneDayProcesses = []
    // moreThanOneDayProcesses.filter((x, xIndex) => {
    //     let dataValue = []
    //     if(x.value && x.value.length > 0) {
    //         x.value.sort((a,b)=> a?.proccess?.start_date < b?.proccess?.start_date ? -1 : a?.proccess?.start_date === b?.proccess?.start_date? 0 : 1)
    //         dataValue = x.value
    //     }
    //     sortedMoreThanOneDayProcesses.push({ key: x.key, value: dataValue, heightWouldBe: x.heightWouldBe })
    // })
    
    // console.log("sortedMoreThanOneDayProcesses: ", sortedMoreThanOneDayProcesses)

   

    // const doneProcesses  = data.filter(i=>i?.proccess?.done || i?.proccess?.quantity === i?.proccess?.finished)
    // const activeProcesses = data.filter(i => !i?.proccess?.done && i?.proccess?.quantity !== i?.proccess?.finished && !i?.proccess?.start_date)
    // const moreThanOneDayProcesses = data.filter(i => !i?.proccess?.done && i?.proccess?.quantity !== i?.proccess?.finished && i?.proccess?.start_date) 
    // const sortedMoreThanOneDayProcesses = moreThanOneDayProcesses.sort((a,b)=> a?.proccess?.start_date < b?.proccess?.start_date ? -1 : a?.proccess?.start_date === b?.proccess?.start_date? 0 : 1)
    // let currentData = [...sortedMoreThanOneDayProcesses, ...activeProcesses, ...doneProcesses]
    // console.log("currentData: ", currentData)

    // if(prevData.length > 0 && currentData.length > 0) { 
    //     prevData.map((prev) => {
    //         if(prev != undefined) {
    //             currentData.map((cur) => {
    //                 if(cur != undefined) {
    //                     prev.value.map((prevval, preIndex) => {
    //                         cur.value.map((curval, curIndex) => {
    //                             if(curval.proccess.fraction) {
    //                                 if(curval.proccess.process_id === prevval.proccess.process_id && curval.proccess.order_id === prevval.proccess.order_id) {
    //                                     let temp = curval[preIndex];
    //                                     curval[preIndex] = curval[curIndex];
    //                                     curval[curIndex] = temp;
    //                                 }
    //                             }
    //                         });
    //                     });
    //                 }
    //             });
    //         }
    //     });
    // }


    // if(prevData.length > 0 && currentData.length > 0) {
    //     for(let i=0; i < prevData.length; i++) {
    //         if(prevData[i] != undefined) {
    //             for(let j=0; j < currentData.length; j++) {
    //                 // if(currentData[j] === undefined || currentData[j] === null) {
    //                 //     console.log("here : ", currentData[j]);
    //                 // }
    //                 if(currentData[j] != undefined) {
    //                     if(currentData[j].proccess.fraction) {
    //                         if(currentData[j].proccess.process_id === prevData[i].proccess.process_id && currentData[j].proccess.order_id === prevData[i].proccess.order_id) {
    //                             // currentData[j].day_duration = prevData[i].day_duration - 1
    //                             let temp = currentData[i]; // (i <= currentData.length -1) ? currentData[i] : {} ;
    //                             currentData[i] = currentData[j];
    //                             currentData[j] = temp;
                                
    //                         }
    //                         //  else {
    //                         //     currentData.splice(j, 0, undefined);
    //                         //     break
    //                         // }
    //                     }
    //                     //  else if(!currentData[j].proccess.fraction && prevData[i].day_duration > 1) {
    //                     //     currentData.splice(j, 0, undefined);
    //                     // }
    //                 }
    //             }
    //         }
    //     }
    // }

    // console.log("currentData: ", currentData)
    prevData = currentData
    return currentData
}